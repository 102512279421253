<template>
  <div class="account-box">
    <div class="wrp-box">
      <div class="act-box">
        <div class="cr-box">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">{{ $t("avatarSelect.AccountInformation") }}</span>
            </div>
            <div>
              <div style="width: 420px; margin: 0 auto">
                <el-row :gutter="20">
                  <el-col :span="10">
                    <div class="item-t">
                      {{ $t("avatarSelect.Email") }}
                    </div>
                    <div class="item-t">
                      {{ $t("avatarSelect.CreatedTime") }}
                    </div>
                    <div class="item-t">
                      {{ $t("avatarSelect.AccountStatus") }}
                    </div>
                    <!-- <div class="item-t">
                      {{ $t('avatarSelect.WxBind') }}
                    </div> -->
                  </el-col>
                  <el-col :span="12">
                    <div class="item-v">{{ act_info.user_name }}</div>
                    <div class="item-v">{{ act_info.create_time }}</div>
                    <div
                      class="item-v"
                      :style="
                        act_info.status == 'Active'
                          ? 'color: #64BCA2'
                          : 'color: red'
                      "
                    >
                      {{ act_info.status }}
                    </div>
                    <!-- <div class="item-v" style="display: flex">
                        <button v-if="visiBind" @click="toWechatBind" style="width:98px;" class="cur hv" type="button">{{ $t('avatarSelect.ToBind') }}</button>
                        <span v-if="visiBund" class="et bound">{{ $t('avatarSelect.Bound') }}</span>
                        <button v-if="visiBund" @click="unBind" style="width:98px;margin-left:10px" class="cur hv" type="button">{{ $t('avatarSelect.Unbind') }}</button>
                    </div> -->
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div class="security-box">
        <div class="cr-box">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">{{ $t("avatarSelect.Security") }}</span>
            </div>
            <div>
              <div class="scry-item">
                <div>
                  <div style="padding-bottom: 0.5rem">{{ $t("avatarSelect.ResetPassword") }}</div>
                  <div>{{ $t("avatarSelect.ResetPasswordTip") }}</div>
                </div>
                <div>
                  <!-- <el-button @click="toResetpwd" style="background: #00bea4; color: #fff"
                    >{{ $t("avatarSelect.ResetPassword") }}</el-button
                  > -->
                  <button @click="toResetpwd" style="" class="cur hv" type="button">{{ $t("avatarSelect.ResetPassword") }}</button>
                </div>
              </div>
              <div class="scry-item" style="border: 0">
                <div>
                  <div style="padding-bottom: 0.5rem">{{ $t("avatarSelect.TwoFactorAuthentication") }}</div>
                  <div>{{ $t("avatarSelect.TwoFactorTip") }}</div>
                </div>
                <div>
                  <el-switch
                    v-model="value"
                    active-color="#00bea4"
                    active-value="1"
                    inactive-value="0"
                    @change="switchVal"
                  >
                  </el-switch>
                </div>
              </div>
              <div class="box-item">
                <div class="bit" style="padding-left:10px">
                  <div class="col-it" style="padding-bottom:0.5rem">{{ $t("avatarSelect.authenticationMethodEmail") }}<span class="et">{{ $t("avatarSelect.Method") }}</span></div>
                  <div class="col-it">{{ $t("avatarSelect.RecoveryCodes") }}</div>
                </div>
                <div class="bit" style="margin-left:auto">
                  <div class="col-it" style="padding-bottom:0.5rem">{{ $t("avatarSelect.authenticationMethodEmailTip") }}</div>
                  <div class="col-it">{{ $t("avatarSelect.CenterDesc") }}</div>
                </div>
                <div class="bit" style="margin-left:auto">
                  <div class="col-it" style="padding-bottom:0.5rem">
                    <div style="opacity:0;text-align:right">
                      <button style="padding:0 6px" class="cur hv" type="button">生成備用碼</button>
                    </div>
                  </div>
                  <div class="col-it">
                    <div style="text-align:right">
                      <button @click="tqVerify" style="padding:2px 6px" class="cur hv" type="button">{{ $t("avatarSelect.Generate") }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="acitem" style="border: 0">
                <el-row :gutter="20" type="flex">
                <el-col :xs="20" :sm="16" :md="10" :lg="10" :xl="6"><div class="col-it">{{ $t("avatarSelect.authenticationMethodEmail") }}<span class="et">{{ $t("avatarSelect.Method") }}</span></div></el-col>
                <el-col :span="12"><div class="col-it">{{ $t("avatarSelect.authenticationMethodEmailTip") }}</div></el-col>
                <el-col :span="6">
                  <div class="col-it">
                    <div style="opacity:0;text-align:right">
                      <button style="padding:0 6px" class="cur hv" type="button">生成備用碼</button>
                    </div>
                  </div>
                </el-col>
              </el-row>
              </div> -->
              <!-- <div class="acitem" style="border: 0">
                <el-row :gutter="20" type="flex">
                <el-col :span="6"><div class="col-it">{{ $t("avatarSelect.RecoveryCodes") }}</div></el-col>
                <el-col :span="12"><div class="col-it">{{ $t("avatarSelect.CenterDesc") }}</div></el-col>
                <el-col :span="6">
                  <div class="col-it">
                    <div style="text-align:right">
                      <button @click="tqVerify" style="padding:0 6px" class="cur hv" type="button">{{ $t("avatarSelect.Generate") }}</button>
                    </div>
                  </div>
                </el-col>
              </el-row>
              </div> -->
              <!-- <div class="box-item">
                <div class="acitem" style="border: 0">
                <div class="nitem" style="justify-content:space-between;">
                  <div>{{ $t("avatarSelect.authenticationMethodEmail") }}
                    <span class="et">{{ $t("avatarSelect.Method") }}</span>
                    
                  </div>
                  <div style="">
                    <span>{{ $t("avatarSelect.authenticationMethodEmailTip") }}</span>
                  </div>
                  <div style="opacity:0">
                    <button style="padding:0 6px" class="cur hv" type="button">生成備用碼</button>
                  </div>
                </div>
                
              </div>
              <div class="acitem" style="border: 0;">
              <div class="nitem" style="justify-content:space-between;">
                  <div style="">{{ $t("avatarSelect.RecoveryCodes") }}</div>
                  <div style="">
                    <span>{{ $t("avatarSelect.CenterDesc") }}</span>
                  </div>
                  <div>
                    <button @click="tqVerify" style="padding:0 6px" class="cur hv" type="button">{{ $t("avatarSelect.Generate") }}</button>
                  </div>
                </div>
              </div>
              </div> -->
            </div>
          </el-card>
        </div>
      </div>

      <div class="fund-box">
        <div class="cr-box" style="padding-bottom: 2rem;">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">{{ $t("avatarSelect.FundAccountInformation") }}</span>
            </div>
            <div>
              <div style="width: 420px; margin: 0 auto">
                <div v-if="isNone">
                  <!-- <el-button @click="toAddFund" style="background: #00bea4; color: #fff">{{
                    $t("avatarSelect.AddFundAccount")
                  }}</el-button> -->
                  <button @click="toAddFund" style="width:126px;" class="cur hv" type="button">{{ $t("avatarSelect.AddFundAccount") }}</button>
                </div>
                <el-row :gutter="20" v-else>
                  <el-col :span="10">
                    <div class="item-t" style="display:flex;align-items:center">
                      {{ $t("avatarSelect.AccountName") }}
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('Funds.Accounts.AccountNameTips')"
                        placement="right-start"
                      >
                        <i class="el-icon-warning" style="color: #e6a23c;padding:0;margin-left:.3rem"></i>
                      </el-tooltip>
                    </div>
                    <div class="item-t">
                      {{ $t("avatarSelect.AccountNumber") }}
                    </div>
                    <div class="item-t">
                      {{ $t("avatarSelect.BankName") }}
                    </div>
                    <div class="item-t">
                      {{ $t("avatarSelect.Status") }}
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item-v">{{ fund_info.account_name }}</div>
                    <div class="item-v">{{ fund_info.account_number }}</div>
                    <div class="item-v">{{ fund_info.bank_name }}</div>
                    <div
                      class="item-v"
                      :style="`color: ${getStatus(fund_info.status)}`"
                    >
                      {{ fund_info.status }}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 驗證身份彈窗 -->
    <div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="verifyVisible"
        width="30%">
        <div slot="title" class="digTitle">
            <div>{{ $t("avatarSelect.VerifyItsYou") }}</div>
          </div>
        <div>
          
          <div>
            <el-alert :title="$t('avatarSelect.VerifyItsYouTopTip')" type="info" show-icon></el-alert>
          </div>
          <div class="sent-tip">
            <span style="font-size:13px">{{ $t("avatarSelect.VerifyItsYouTopTip2") }}</span>
          </div>
          <div class="verify-code-box">
            <label>{{ $t("avatarSelect.VerifyCode") }}</label>
            <el-input v-model="code6" placeholder="Code"></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="jx-btn" @click="jxHandler">{{ $t("avatarSelect.Continue") }}</el-button>
          <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
        </span>
      </el-dialog>
    </div>
    <!-- 查看備用碼 -->
    <div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="ckVerifyVisible"
        :show-close="false"
        width="30%">
          <div slot="title" class="">
              <div class="title-ck-box">
                <div class="digTitle" style="font-size:18px">{{ $t("avatarSelect.RecoveryCodes") }}</div>
                <div class="ck-btn-box">
                  <el-button @click="clickCopy" class="jx-btn" size="small" style="background:#00BEA4;color:#fff">{{ $t("avatarSelect.CopyBtn") }}</el-button>
                  <!-- <el-button class="jx-btn" style="background:#00BEA4;color:#fff" size="small">下載</el-button> -->
                </div>
              </div>
              <div class="title-ck-tip">
                <span style="font-size:13px">{{ $t("avatarSelect.LookCodeTip") }}</span>
              </div>
          </div>
        <div>
          <div class="code-ck-box" id="colContent">
            <el-row :gutter="20">
              <el-col :span="12" v-for="(item,index) in codeArr" :key="index"><div class="grid-content bg-purple">{{item}}</div></el-col>
              <!-- <el-col :span="12"><div class="grid-content bg-purple">33333333</div></el-col>
              <el-col :span="12"><div class="grid-content bg-purple">57798808</div></el-col>

              <el-col :span="12"><div class="grid-content bg-purple">23143545</div></el-col>

              <el-col :span="12"><div class="grid-content bg-purple">12446576</div></el-col>

              <el-col :span="12"><div class="grid-content bg-purple">11111111</div></el-col>

              <el-col :span="12"><div class="grid-content bg-purple">32423543</div></el-col> -->

            </el-row>
          </div>
          <div style="padding-top:20px">
            <el-alert :title="$t('avatarSelect.LookCodeTip2')" type="info" show-icon></el-alert>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="jx-btn" @click="gbHandler">{{ $t("avatarSelect.CloseBtn") }}</el-button>
          <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getAccountSetInfo, unBingWx,updateRecoveryCode,getEmailCode } from "../../api/http";
export default {
  data() {
    return {
      act_info: "",
      fund_info: "",
      isNone: false,
      value: "0",
      timer: null,
      visiBind: false,
      visiBund: false,
      verifyVisible: false,
      ckVerifyVisible: false,
      code6: null,
      // codeArr: ['12345678','22222222','22212232','52222222','22224222','22221922']
      codeArr: []
    };
  },
  watch: {
    act_info(val) {
      console.log("act===>", val)
      if(val.wx_oid == "" || val.wx_oid == null) {
        this.visiBind = true
        this.visiBund = false
      }else {
        this.visiBund = true
        this.visiBind = false
      }
    }
  },
  methods: {
    // 打開驗證身份彈窗
    tqVerify() {
      this.verifyVisible = true
      this.reqGetEmailCode()
    },
    // 繼續
    jxHandler() {
      
      if(this.code6 == null) {
        return
      }else {
        this.reqUpdateRecoveryCode()
        // this.ckVerifyVisible = true
          // this.verifyVisible = false
      }
    },
    // 關閉
    gbHandler() {
      this.ckVerifyVisible = false
      this.code6 = null
    },
    // 請求獲取驗證碼
    reqGetEmailCode() {
      let data = {
        type: 'get_email_code',
        code_type: '2fa',
        user_name: localStorage.getItem('user_name')
      }
      getEmailCode(data).then((res) => {
        if(res.code == 200) {
          
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      })
    },
    // 請求更新備用碼
    reqUpdateRecoveryCode() {
      let data = {
        type: 'update_recovery_code',
        uid: localStorage.getItem('uid'),
        email_code: this.code6
      }
      updateRecoveryCode(data).then((res) => {
        if(res.code == 200) {
          this.ckVerifyVisible = true
          this.verifyVisible = false
          this.codeArr = res.data
        }else {
          this.$message.error(res.message)
        }
      })
    },
    getStatus(status) {
      return status === "Verifying"
        ? "#4CA1E5"
        : status === "Complete"
        ? "#00BEA4"
        : "#F56C6C";
    },
    copyData(data) {
      // let variable = data.map(item => {return `${item}\r\n`})
      let variable = ``
      data.map((item, index) => {
        // for(let key in item) {
        //   variable += `${item}\t`
        // }
        variable += `${item}`
        // variable += '\n'
      })
      var tag = document.createElement('textarea')
      document.body.appendChild(tag)
      tag.value = variable
      tag.select()
      document.execCommand('copy', false)
      tag.remove()
    },
    // 格式複製方法
    selectElementContents(el) {
      var body = document.body,
          range,
          sel;
          console.log('sel==>', sel)
          console.log('range==>', range)
      if(document.createRange && window.getSelection) {
        range = document.createRange()
        sel = window.getSelection()
        sel.removeAllRanges()
        try {
          range.selectNodeContents(el)
          sel.addRange(range)
          console.log('range==>', range)
        } catch(e) {
          range.selectNode(el)
          sel.addRange(range)
        }
        document.execCommand("copy")
        // 取消文本選中狀態
        window.getSelection().empty()
        this.$message.success("Copied")
      }    
    },
    // 點擊複製
    clickCopy() {
      this.selectElementContents(document.getElementById("colContent"))
      // this.copyData(this.codeArr)
    },
    toResetpwd() {
        this.$router.push("/users/resetpassword")
    },
    toAddFund() {
        this.$router.push("/funds/accounts")
    },
    toWechatBind() {
      this.$router.push("/users/wechat-bind")
    },
    switchVal() {
        let obj = {
            type: "update_2fa_login",
            uid: window.localStorage.getItem("uid"),
            switch: this.value
        }
        let formData = new FormData();
        for (let k in obj) {
            formData.append(k, obj[k]);
        }
        this.$axios({
        method: "POST",
        url: "/request/user.php",
        data: formData,
      }).then((code) => {
        if (code.data.code == 200) {
          this.getData()
        } else {
          this.$message.error(code.data.message);
        }
      });
    },
    // 请求解除绑定接口
    reqUnBindWx() {
      let obj = {
        type: "unbind_wx",
        uid: localStorage.getItem('uid') 
      }
      unBingWx(obj).then((res) => {
        if(res.code == 200) {
          this.$message.success(res.message)
          this.getData()
        }else {
          this.$message.error(res.message)
        }
      })
    },
    // 解除微信绑定
    unBind() {
      let that = this
      this.$confirm(this.$t('avatarSelect.TipMessage'), '', {
          confirmButtonText: that.$t('avatarSelect.Confirm'),
          cancelButtonText: that.$t('avatarSelect.Cancel'),
          showClose: false,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          that.reqUnBindWx()
        }).catch(() => {
                   
        });
    },
    getData() {
      let data = {
        type: "get_account_setting_info",
        uid: window.localStorage.getItem("uid"),
      };
      getAccountSetInfo(data).then((res) => {
        if (res.code == 200) {
          this.act_info = res.data.account_info[0];
          this.value = res.data.account_info[0].login_2fa
          if (res.data.fund_account.length == 0) {
            this.isNone = true;
          } else {
            this.isNone = false;
            this.fund_info = res.data.fund_account[0];
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>


<style scoped>
.box-item{
  width: 100%;
  display: flex;
  background: #F5F6F7;
  padding: 1rem 0;
  color: #42474d;
  font-size: 14px;
  font-family: AxLLCircular, Helvetica, Arialsans-serif;
}
.sent-tip{
  padding: 20px 0;
}
.digTitle{
  font-size: 22px;
  font-weight: bold;
}
.jx-btn{
  outline: 0;
}
.jx-btn:hover{
    background: #E6F9F6;
    color: #00BEA4;
    border-color: #00BEA4;
}
.jx-btn:focus{
    background: #E6F9F6;
    color: #00BEA4;
    border-color: #00BEA4;
    outline: 0;
}
.title-ck-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-ck-tip{
  font-size: 14px;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}
.grid-content{
  padding-bottom: 10px;
  font-weight: bold;
  font-family: Microsoft Yahei;
  letter-spacing: 2px;
}
.bound{
  margin-left: 0 !important;
  color: #424c4d;
  font-weight: normal;
}
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 100%;
  outline:0;
}
/*  */
.cur{
  outline: none;
  border: none;
  color: #00BEA4;
  background: transparent;
  padding: 6px 12px;
  font-weight: bold;
}
.hv:hover{
  background: rgb(0,190,164);
  color: #fff;
  border-radius: 0.375rem;
}
.account-box {
  height: 100%;
  background: #fff;
  padding-left: 2.6rem;
}
.cr-box {
  width: 83%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 2rem;
}
.item-t {
  /* color: #3c2b2b; */
  /* font-weight: bold; */
  padding-bottom: 1.25rem;
  color: #42474d;
  font-size: 14px;
  font-family: AxLLCircular, Helvetica, Arialsans-serif;

}
.item-v {
  color: #a196aa;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 1.25rem;
}
.scry-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #ebeef5;
  color: #42474d;
  font-size: 14px;
  font-family: AxLLCircular, Helvetica, Arialsans-serif;
}
.acitem{
    width: 100%;
    height: 33px;
    line-height: 33px;
    background: #F5F6F7;
    padding-left: 1rem;
    padding-right: 1rem;
}
.card-title{
    font-weight: bold;
    font-size: 1.1rem;
}
.et{
    padding: 0.3rem 0.5rem;
    background: #DFF6E6;
    margin-left: 0.5rem;
    border-radius: 5px;
}
.nitem{
  display: flex;
  align-items: center;
  height: 33px;
  line-height: 33px;
}
</style>